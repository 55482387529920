import React from 'react';
import { AppBar, Toolbar, Button, Grid, Box } from '@mui/material';
import nttDataLogo from '../assets/noBG-nttData_logo.png';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const realm = 'NTT_DATA';

    const handleLogout = () => {
        const idToken = sessionStorage.getItem('idToken');

        sessionStorage.clear();
        localStorage.clear();

        const keycloakLogoutUrl = `https://auth.innovation.realware.app/realms/${realm}/protocol/openid-connect/logout`;

        if (idToken) {
            window.location.href = `${keycloakLogoutUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}`;
        } else {
            navigate('/');
        }
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: '#f5f5f5',
                boxShadow: 'none',
                borderBottom: '1px solid #e0e0e0',
                zIndex: 1300,
            }}
        >
            <Toolbar>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} sm={8}>
                        <Box display="flex" alignItems="center">
                            <img
                                src={nttDataLogo}
                                alt="Company Logo"
                                style={{
                                    maxWidth: '120px',
                                    height: 'auto',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
                        <Button
                            sx={{
                                color: '#ff1744',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: '#d50000',
                                },
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Button, Grid, Box } from '@mui/material';
// import nttDataLogo from '../assets/noBG-nttData_logo.png'; // Import the logo
// import { useNavigate } from 'react-router-dom';

// const Header = () => {
//     const navigate = useNavigate();

//     const handleLogout = () => {
//         sessionStorage.clear();
//         navigate('/');
//     };

//     return (
//         <AppBar
//             position="static"
//             sx={{
//                 backgroundColor: '#f5f5f5', // Modern light background color
//                 boxShadow: 'none', // Remove shadow
//                 borderBottom: '1px solid #e0e0e0', // Flat border to replace the shadow
//             }}
//         >
//             <Toolbar>
//                 <Grid container alignItems="center" justifyContent="space-between">
//                     <Grid item xs={6} sm={8}>
//                         <Box display="flex" alignItems="center">
//                             <img
//                                 src={nttDataLogo}
//                                 alt="Company Logo"
//                                 style={{
//                                     maxWidth: '120px', // Adjust the logo size for a more sleek appearance
//                                     height: 'auto',
//                                 }}
//                             />
//                             {/* <Typography
//                                 variant="h6"
//                                 sx={{
//                                     marginLeft: '10px',
//                                     color: '#333', // Dark modern text color
//                                     fontWeight: 'bold',
//                                 }}
//                             >
//                                 Company Name
//                             </Typography> */}
//                         </Box>
//                     </Grid>
//                     <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
//                         <Button
//                             sx={{
//                                 color: '#ff1744', // Modern red accent for logout
//                                 fontWeight: 'bold',
//                                 '&:hover': {
//                                     backgroundColor: 'transparent',
//                                     color: '#d50000', // Darker red on hover
//                                 },
//                             }}
//                             onClick={handleLogout}
//                         >
//                             Logout
//                         </Button>
//                     </Grid>
//                 </Grid>
//             </Toolbar>
//         </AppBar>
//     );
// };

// export default Header;
