import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
    Typography,
    Box,
    Button,
    Grid,
    Card,
    CardContent,
    IconButton,
    TextField,
    Modal,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import Header from "../components/Header";
import "../styles/ExtractorScreenStyles.css"; // Import custom styles
import { LinearProgress } from "@material-ui/core";
import axios from 'axios'; // Axios for API requests

const ExtractorScreen = () => {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [responses, setResponses] = useState([]); // Store responses
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // For image modal

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png, image/jpg",
        onDrop: (acceptedFiles) => {
            const newImages = acceptedFiles.map((file) => ({
                preview: URL.createObjectURL(file),
                name: file.name,
                file: file, // Keep file for upload
                status: 'pending', // Initial status for each image
                uploading: false // Flag to show if it's uploading
            }));
            setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
        },
    });

    const handleDeleteImage = (index) => {
        const newImages = uploadedImages.filter((_, i) => i !== index);
        setUploadedImages(newImages);
        const newResponses = responses.filter((_, i) => i !== index);
        setResponses(newResponses);
    };

    const handleExtract = async () => {
        setLoading(true);
        const newResponses = [...responses]; // Create a copy of the current responses

        for (let i = 0; i < uploadedImages.length; i++) {
            const image = uploadedImages[i];
            if (responses[i]) continue; // Skip if we already have a response for this image

            // Set the uploading flag for the current image
            const newImages = [...uploadedImages];
            newImages[i].uploading = true;
            setUploadedImages(newImages);

            try {
                // 1. Upload the image to the first API
                const formData = new FormData();
                formData.append('file', image.file);

                const extractResponse = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/uploadToBlob?fileId=${image.name}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });

                const { fileName } = extractResponse.data.fileInfo;

                // 2. Fetch the SKU details from the second API using the fileName
                const skuResponse = await axios.get(`${process.env.REACT_APP_FEATURE_API_URL}/features/labelOCR/getSKUDetails?fileId=${fileName}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
                    }
                });


                newResponses[i] = skuResponse.data[0].data; // Store the response data at the correct index
                setResponses([...newResponses]); // Update state with the new responses

                // Mark as completed and turn off uploading flag
                newImages[i].status = 'completed'; // Update status to completed
                newImages[i].uploading = false; // Stop the uploading
                setUploadedImages(newImages);

            } catch (error) {
                console.error("Error uploading image or fetching SKU details:", error);
            }
        }
        setLoading(false);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image); // Show the image in a larger modal
    };

    const handleCloseModal = () => {
        setSelectedImage(null); // Close the image modal
    };

    const handleReset = () => {
        setUploadedImages([]);
        setResponses([]);
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="uploader-container">
                    <Box
                        {...getRootProps()}
                        className="dropzone"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: uploadedImages.length ? "10px" : "40px",
                            border: "3px dashed #ccc",
                            borderRadius: "25px",
                            cursor: "pointer",
                            backgroundColor: "#fcfcfc",
                            width: "100%",
                            maxWidth: "1000px",
                            minHeight: uploadedImages.length ? "100px" : "250px",
                            transition: "all 0.3s ease-in-out",
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="h6" align="center" color="textPrimary">
                            Drag and drop images here
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            Files supported: JPG | PNG | JPEG
                        </Typography>
                    </Box>
                </div>

                {/* Image Preview Box */}
                {uploadedImages.length > 0 && (
                    <div className="preview-container">
                        <Grid container spacing={2} className="image-preview-grid">
                            {uploadedImages.map((image, index) => (
                                <Grid item xs={12} key={index} className="image-preview-item">
                                    <Card sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
                                        <CardContent sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            width: { xs: '100%', sm: '150px' }, // Increase image size for larger screens
                                                            height: "auto",
                                                            borderRadius: "8px",
                                                            objectFit: "cover",
                                                            display: "block",
                                                            cursor: "pointer",
                                                        }}
                                                        src={image.preview}
                                                        alt={`Uploaded image ${index + 1}`}
                                                        onClick={() => handleImageClick(image)} // Handle image click
                                                    />
                                                    <Typography variant="caption" display="block">
                                                        {image.name}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={7}>
                                                    {responses[index] ? (
                                                        <Box
                                                            sx={{
                                                                maxWidth: "400px",
                                                                maxHeight: '250px',
                                                                overflowY: 'auto',
                                                                padding: '10px',
                                                                borderRadius: '8px'
                                                            }}
                                                        >
                                                            {responses[index].map((label, i) => (
                                                                // <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px", maxWidth: "400px" }}>
                                                                //     <Grid container justifyContent="flex-end" spacing={1}>
                                                                //         {/* First Row */}
                                                                //         <Grid item>
                                                                //             <TextField
                                                                //                 defaultValue={label.box0}
                                                                //                 variant="outlined"
                                                                //                 InputProps={{ style: { textAlign: "center" } }}
                                                                //                 sx={{ minWidth: '50px', width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }}
                                                                //             />
                                                                //         </Grid>
                                                                //         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                //         <Grid item>
                                                                //             <TextField
                                                                //                 defaultValue={label.box1}
                                                                //                 variant="outlined"
                                                                //                 InputProps={{ style: { textAlign: "center" } }}
                                                                //                 sx={{ minWidth: '50px', width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
                                                                //             />
                                                                //         </Grid>
                                                                //         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                //         <Grid item>
                                                                //             <TextField
                                                                //                 defaultValue={label.box2}
                                                                //                 variant="outlined"
                                                                //                 InputProps={{ style: { textAlign: "center" } }}
                                                                //                 sx={{ minWidth: '50px', width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
                                                                //             />
                                                                //         </Grid>

                                                                //         {/* Second Row */}
                                                                //         <Grid container justifyContent="flex-end" spacing={1} sx={{ marginTop: "10px" }}>
                                                                //             <Grid item>
                                                                //                 <TextField
                                                                //                     defaultValue={label.box3}
                                                                //                     variant="outlined"
                                                                //                     InputProps={{ style: { textAlign: "center" } }}
                                                                //                     sx={{ minWidth: '50px', width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
                                                                //                 />
                                                                //             </Grid>
                                                                //             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                //             <Grid item>
                                                                //                 <TextField
                                                                //                     defaultValue={label.box4}
                                                                //                     variant="outlined"
                                                                //                     InputProps={{ style: { textAlign: "center" } }}
                                                                //                     sx={{ minWidth: '50px', width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
                                                                //                 />
                                                                //             </Grid>
                                                                //             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                //             <Grid item>
                                                                //                 <TextField
                                                                //                     defaultValue={label.box5}
                                                                //                     variant="outlined"
                                                                //                     InputProps={{ style: { textAlign: "center" } }}
                                                                //                     sx={{ minWidth: '50px', width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
                                                                //                 />
                                                                //             </Grid>
                                                                //         </Grid>
                                                                //     </Grid>

                                                                //     <Typography variant="body2" sx={{ marginTop: "10px" }}>
                                                                //         {label.NumPieces} - {label.ItemDesc}
                                                                //     </Typography>
                                                                // </Box>
                                                                <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px", position: 'relative', maxWidth: "600px" }}>
                                                                    <Grid container justifyContent="flex-end" spacing={1}>
                                                                        {/* Left-Side SKU Text (Vertical) */}
                                                                        <Grid item sx={{ position: 'absolute', left: '-5px', top: '50%', transform: 'translateY(-50%)', fontWeight: 'bold' }}>
                                                                            <Typography variant="body1" sx={{ whiteSpace: 'pre', textAlign: 'left', fontWeight: "300" }}>
                                                                                S{'\n'}
                                                                                K{'\n'}
                                                                                U
                                                                            </Typography>
                                                                        </Grid>

                                                                        {/* First Row */}
                                                                        <Grid item>
                                                                            <TextField
                                                                                defaultValue={label.box0}
                                                                                variant="outlined"
                                                                                InputProps={{ style: { textAlign: "center" } }}
                                                                                sx={{ minWidth: '50px', width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }}
                                                                            />
                                                                        </Grid>
                                                                        <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                        <Grid item>
                                                                            <TextField
                                                                                defaultValue={label.box1}
                                                                                variant="outlined"
                                                                                InputProps={{ style: { textAlign: "center" } }}
                                                                                sx={{ minWidth: '50px', width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
                                                                            />
                                                                        </Grid>
                                                                        <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                        <Grid item>
                                                                            <TextField
                                                                                defaultValue={label.box2}
                                                                                variant="outlined"
                                                                                InputProps={{ style: { textAlign: "center" } }}
                                                                                sx={{ minWidth: '50px', width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
                                                                            />
                                                                        </Grid>

                                                                        {/* Second Row */}
                                                                        <Grid container justifyContent="flex-end" spacing={1} sx={{ marginTop: "10px" }}>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    defaultValue={label.box3}
                                                                                    variant="outlined"
                                                                                    InputProps={{ style: { textAlign: "center" } }}
                                                                                    sx={{ minWidth: '50px', width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
                                                                                />
                                                                            </Grid>
                                                                            <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    defaultValue={label.box4}
                                                                                    variant="outlined"
                                                                                    InputProps={{ style: { textAlign: "center" } }}
                                                                                    sx={{ minWidth: '50px', width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
                                                                                />
                                                                            </Grid>
                                                                            <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    defaultValue={label.box5}
                                                                                    variant="outlined"
                                                                                    InputProps={{ style: { textAlign: "center" } }}
                                                                                    sx={{ minWidth: '50px', width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Typography variant="body2" sx={{ marginTop: "10px" }}>
                                                                        {label.NumPieces} - {label.ItemDesc}
                                                                    </Typography>
                                                                </Box>

                                                            ))}
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {image.uploading ? (
                                                                <>
                                                                    <LinearProgress />
                                                                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
                                                                        Submitting...
                                                                    </Typography>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <LinearProgress variant="determinate" />
                                                                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
                                                                        Ready to upload and Extract
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Grid>

                                                <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                                                    <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="print">
                                                        <PrintIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="download">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '20px' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleReset}
                                disabled={loading}
                                sx={{
                                    backgroundColor: "#f44336", // Red color for reset action
                                    width: 'auto',
                                    padding: '10px 5px',
                                    marginRight: "10px",
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Reset
                            </Button>

                            {/* Download All Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                sx={{
                                    backgroundColor: "#313043", // Blue color for download action
                                    width: 'auto',
                                    padding: '5px 10px',
                                    marginRight: "10px",
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Download All
                            </Button>

                            {/* Submit and Extract Button */}
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleExtract}
                                disabled={loading}
                                sx={{
                                    width: 'auto',
                                    padding: '0px 6px',
                                    marginRight: "10px",
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Submit and Extract
                            </Button>
                        </div>
                    </div>
                )}

                {/* Modal for Image Preview */}
                {selectedImage && (
                    <Modal
                        open={!!selectedImage}
                        onClose={handleCloseModal}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '80%', maxWidth: '800px', maxHeight: '80vh' }}>
                            <img
                                src={selectedImage.preview}
                                alt={selectedImage.name}
                                style={{ maxWidth: "400px", height: 'auto', borderRadius: '8px' }}
                            />
                            <Button onClick={handleCloseModal} sx={{ marginTop: '10px' }} variant="contained" color="secondary">
                                Close
                            </Button>
                        </Box>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
//     Modal,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import PrintIcon from '@mui/icons-material/Print';
// import DownloadIcon from '@mui/icons-material/Download';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";
// import axios from 'axios'; // Axios for API requests

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null); // For image modal

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };
//     const handleReset = () => {
//         setUploadedImages([]);
//         setResponses([]);
//         setLoading(false);
//     };
//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             try {
//                 // 1. Upload the image to the first API
//                 const formData = new FormData();
//                 formData.append('file', image.file);

//                 const extractResponse = await axios.post(`http://192.168.1.83:3000/uploadToBlob?fileId=${image.name}`, formData, {
//                     headers: {
//                         'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
//                     }
//                 });

//                 const { fileName } = extractResponse.data.fileInfo;

//                 // 2. Fetch the SKU details from the second API using the fileName
//                 const skuResponse = await axios.get(`http://192.168.1.150:6101/features/labelOCR/getSKUDetails?fileId=${fileName}`, {
//                     headers: {
//                         'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
//                     }
//                 });

//                 newResponses[i] = skuResponse.data.data; // Store the response data at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image or fetching SKU details:", error);
//             }
//         }
//         setLoading(false);
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image); // Show the image in a larger modal
//     };

//     const handleCloseModal = () => {
//         setSelectedImage(null); // Close the image modal
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: { xs: '100%', sm: '150px' }, // Increase image size for larger screens
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                             cursor: "pointer",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                         onClick={() => handleImageClick(image)} // Handle image click
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={7}>
//                                                     {responses[index] ? (
//                                                         <Box
//                                                             sx={{
//                                                                 maxWidth: "400px",
//                                                                 maxHeight: '250px',
//                                                                 overflowY: 'auto',
//                                                                 padding: '10px',
//                                                                 // border: '1px solid #ddd',
//                                                                 borderRadius: '8px'
//                                                             }}
//                                                         >
//                                                             {responses[index].map((label, i) => (
//                                                                 <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px", maxWidth: "400px" }}>
//                                                                     <Typography variant="h6" sx={{ textOrientation: "upright" }}>SKU</Typography>

//                                                                     <Grid container justifyContent="flex-end" spacing={1}>
//                                                                         {/* First Row */}
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box0}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box1}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box2}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                             />
//                                                                         </Grid>

//                                                                         {/* Second Row */}
//                                                                         <Grid container justifyContent="flex-end" spacing={1} sx={{ marginTop: "10px" }}>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box3}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box4}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box5}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                         </Grid>
//                                                                     </Grid>

//                                                                     <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                         {label.NumPieces} - {label.ItemDesc}
//                                                                     </Typography>
//                                                                 </Box>
//                                                             ))}
//                                                         </Box>
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                     <IconButton aria-label="print"
//                                                     // onClick={() => handlePrintImage(index)}
//                                                     >
//                                                         <PrintIcon />
//                                                     </IconButton>
//                                                     <IconButton aria-label="download"
//                                                     // onClick={() => handleDownloadImage(index)}
//                                                     >
//                                                         <DownloadIcon />
//                                                     </IconButton>
//                                                 </Grid>

//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '20px' }}>
//                             <Button
//                                 variant="contained"
//                                 color="secondary"
//                                 onClick={handleReset}
//                                 disabled={loading}
//                                 sx={{
//                                     backgroundColor: "#f44336", // Red color for reset action
//                                     width: 'auto',
//                                     padding: '10px 5px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Reset
//                             </Button>

//                             {/* Download All Button */}
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 disabled={loading}
//                                 sx={{
//                                     backgroundColor: "#313043", // Blue color for download action
//                                     width: 'auto',
//                                     padding: '5px 10px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Download All
//                             </Button>

//                             {/* Submit and Extract Button */}
//                             <Button
//                                 variant="contained"
//                                 color="success"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     // backgroundColor: "#4caf50", // Green color for submit action
//                                     width: 'auto',
//                                     padding: '0px 6px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>

//                     </div>
//                 )}

//                 {/* Modal for Image Preview */}
//                 {selectedImage && (
//                     <Modal
//                         open={!!selectedImage}
//                         onClose={handleCloseModal}
//                         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                     >
//                         <Box sx={{ width: '80%', maxWidth: '800px', maxHeight: '80vh', }}>
//                             <img
//                                 src={selectedImage.preview}
//                                 alt={selectedImage.name}
//                                 style={{ maxWidth: "400px", height: 'auto', borderRadius: '8px' }}
//                             />
//                             <Button onClick={handleCloseModal} sx={{ marginTop: '10px' }} variant="contained" color="secondary">
//                                 Close
//                             </Button>
//                         </Box>
//                     </Modal>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
//     Modal,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null); // For image modal

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 newResponses[i] = response.data; // Store the response at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         },
//                         // {
//                         //     box0: "24",
//                         //     box1: "472039",
//                         //     box2: "(42-08)",
//                         //     box3: "09",
//                         //     box4: "006",
//                         //     box5: "000",
//                         //     NumPieces: "20 Pcs",
//                         //     ItemDesc: "DRY T-shirts"
//                         // }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image); // Show the image in a larger modal
//     };

//     const handleCloseModal = () => {
//         setSelectedImage(null); // Close the image modal
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: { xs: '100%', sm: '150px' }, // Increase image size for larger screens
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                             cursor: "pointer",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                         onClick={() => handleImageClick(image)} // Handle image click
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={7}>
//                                                     {responses[index] ? (
//                                                         <Box
//                                                             sx={{
//                                                                 maxWidth: "400px",
//                                                                 maxHeight: '250px',
//                                                                 overflowY: 'auto',
//                                                                 padding: '10px',
//                                                                 // border: '1px solid #ddd',
//                                                                 borderRadius: '8px'
//                                                             }}
//                                                         >
//                                                             {responses[index].map((label, i) => (
//                                                                 <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px", maxWidth: "350px" }}>
//                                                                     <Grid container justifyContent="flex-end" spacing={1}>
//                                                                         {/* First Row */}
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box0}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box1}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box2}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                             />
//                                                                         </Grid>

//                                                                         {/* Second Row */}
//                                                                         <Grid container justifyContent="flex-end" spacing={1} sx={{ marginTop: "10px" }}>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box3}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box4}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                             <Typography variant="h6" sx={{ alignSelf: "center" }}>-</Typography>
//                                                                             <Grid item>
//                                                                                 <TextField
//                                                                                     defaultValue={label.box5}
//                                                                                     variant="outlined"
//                                                                                     InputProps={{ style: { textAlign: "center" } }}
//                                                                                     sx={{ minWidth: '50px', width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                                 />
//                                                                             </Grid>
//                                                                         </Grid>
//                                                                     </Grid>

//                                                                     <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                         {label.NumPieces} - {label.ItemDesc}
//                                                                     </Typography>
//                                                                 </Box>
//                                                             ))}
//                                                         </Box>
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         {/* <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div> */}
//                         <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '20px' }}>
//                             {/* Reset Button */}
//                             <Button
//                                 variant="contained"
//                                 color="secondary"
//                                 disabled={loading}
//                                 sx={{
//                                     backgroundColor: "#f44336", // Red color for reset action
//                                     width: 'auto',
//                                     padding: '10px 5px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Reset
//                             </Button>

//                             {/* Download All Button */}
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 disabled={loading}
//                                 sx={{
//                                     backgroundColor: "#313043", // Blue color for download action
//                                     width: 'auto',
//                                     padding: '5px 10px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Download All
//                             </Button>

//                             {/* Submit and Extract Button */}
//                             <Button
//                                 variant="contained"
//                                 color="success"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     // backgroundColor: "#4caf50", // Green color for submit action
//                                     width: 'auto',
//                                     padding: '0px 6px',
//                                     marginRight: "10px",
//                                     borderRadius: "8px",
//                                     fontSize: "12px",

//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>

//                     </div>
//                 )}

//                 {/* Modal for Image Preview */}
//                 {selectedImage && (
//                     <Modal
//                         open={!!selectedImage}
//                         onClose={handleCloseModal}
//                         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                     >
//                         <Box sx={{ width: '80%', maxWidth: '800px', maxHeight: '80vh', }}>
//                             <img
//                                 src={selectedImage.preview}
//                                 alt={selectedImage.name}
//                                 style={{ maxWidth: "400px", height: 'auto', borderRadius: '8px' }}
//                             />
//                             <Button onClick={handleCloseModal} sx={{ marginTop: '10px' }} variant="contained" color="secondary">
//                                 Close
//                             </Button>
//                         </Box>
//                     </Modal>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
//     Modal,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null); // For image modal

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 newResponses[i] = response.data; // Store the response at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         },
//                         {
//                             box0: "24",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "20 Pcs",
//                             ItemDesc: "DRY T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image); // Show the image in a larger modal
//     };

//     const handleCloseModal = () => {
//         setSelectedImage(null); // Close the image modal
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center", justifyContent: "space-between", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                             cursor: "pointer",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                         onClick={() => handleImageClick(image)} // Handle image click
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={7}>
//                                                     {responses[index] ? (
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px" }}>
//                                                                 <Grid container justifyContent="flex-end" spacing={1}>
//                                                                     {/* First Row */}
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box0}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ minWidth: '50px', width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box1}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ minWidth: '50px', width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box2}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ minWidth: '50px', width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                         />
//                                                                     </Grid>

//                                                                     {/* Second Row */}
//                                                                     <Grid container justifyContent="flex-end" spacing={1} sx={{ marginTop: "10px" }}>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box3}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6">-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box4}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                         <Typography variant="h6">-</Typography>
//                                                                         <Grid item>
//                                                                             <TextField
//                                                                                 defaultValue={label.box5}
//                                                                                 variant="outlined"
//                                                                                 InputProps={{ style: { textAlign: "center" } }}
//                                                                                 sx={{ minWidth: '50px', width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                             />
//                                                                         </Grid>
//                                                                     </Grid>
//                                                                 </Grid>

//                                                                 <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}

//                 {/* Modal for Image Preview */}
//                 {selectedImage && (
//                     <Modal
//                         open={!!selectedImage}
//                         onClose={handleCloseModal}
//                         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                     >
//                         <Box sx={{ width: '80%', maxWidth: '800px', maxHeight: '80vh', }}>
//                             <img
//                                 src={selectedImage.preview}
//                                 alt={selectedImage.name}
//                             // style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
//                             />
//                             <Button onClick={handleCloseModal} sx={{ marginTop: '10px' }} variant="contained" color="secondary">
//                                 Close
//                             </Button>
//                         </Box>
//                     </Modal>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
//     Modal,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null); // For image modal

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 newResponses[i] = response.data; // Store the response at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         },
//                         {
//                             box0: "24",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "20 Pcs",
//                             ItemDesc: "DRY T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image); // Show the image in a larger modal
//     };

//     const handleCloseModal = () => {
//         setSelectedImage(null); // Close the image modal
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                             cursor: "pointer",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                         onClick={() => handleImageClick(image)} // Handle image click
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={5} sx={{ textAlign: 'right' }}>
//                                                     {responses[index] ? (
//                                                         // Display response data as an editable label
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px" }}>
//                                                                 {/* First row */}
//                                                                 <Grid container alignItems="center" justifyContent="flex-end">
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box0}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }} // Adjust width based on content
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box1}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box2}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>

//                                                                 {/* Second row */}
//                                                                 <Grid container alignItems="center" justifyContent="flex-end" sx={{ marginTop: "10px" }}>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box3}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box4}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box5}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>

//                                                                 <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}

//                 {/* Modal for Image Preview */}
//                 {selectedImage && (
//                     <Modal
//                         open={!!selectedImage}
//                         onClose={handleCloseModal}
//                         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                     >
//                         <Box sx={{ width: '80%', maxWidth: '800px', maxHeight: '80vh', }}>
//                             <img
//                                 src={selectedImage.preview}
//                                 alt={selectedImage.name}
//                             // style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
//                             />
//                             <Button onClick={handleCloseModal} sx={{ marginTop: '10px' }} variant="contained" color="secondary">
//                                 Close
//                             </Button>
//                         </Box>
//                     </Modal>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 newResponses[i] = response.data; // Store the response at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={5}>
//                                                     {responses[index] ? (
//                                                         // Display response data as an editable label
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px" }}>
//                                                                 {/* First row */}
//                                                                 <Grid container alignItems="center">
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box0}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box0.length * 10 + 40}px`, marginRight: '8px' }} // Adjust width based on content
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box1}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box1.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box2}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box2.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>

//                                                                 {/* Second row */}
//                                                                 <Grid container alignItems="center" sx={{ marginTop: "10px" }}>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box3}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box3.length * 10 + 40}px`, marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box4}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box4.length * 10 + 40}px`, marginLeft: '8px', marginRight: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Typography variant="h6">-</Typography>
//                                                                     <Grid item>
//                                                                         <TextField
//                                                                             defaultValue={label.box5}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                             sx={{ width: `${label.box5.length * 10 + 40}px`, marginLeft: '8px' }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>

//                                                                 <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages((prevImages) => [...prevImages, ...newImages]); // Append new images to the state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         const newResponses = [...responses]; // Create a copy of the current responses

//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             if (responses[i]) continue; // Skip if we already have a response for this image

//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 newResponses[i] = response.data; // Store the response at the correct index
//                 setResponses([...newResponses]); // Update state with the new responses

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={8}>
//                                                     {responses[index] ? (
//                                                         // Display response data as an editable label
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px" }}>
//                                                                 <Grid container spacing={2}>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box0}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box1}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box2}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box3}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box4}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box5}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>
//                                                                 <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     TextField,

// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles
// import { LinearProgress } from "@material-ui/core";

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages([...uploadedImages, ...newImages]);
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 const newResponse = [...responses];
//                 newResponse[i] = response.data;
//                 setResponses(newResponse);

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={8}>
//                                                     {responses[index] ? (
//                                                         // Display response data as an editable label
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px', border: "1px solid #000", padding: "10px", borderRadius: "3px" }}>
//                                                                 <Grid container spacing={2}>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box0}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box1}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box2}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box3}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box4}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                     <Grid item xs={2}>
//                                                                         <TextField
//                                                                             defaultValue={label.box5}
//                                                                             variant="outlined"
//                                                                             InputProps={{ style: { textAlign: "center" } }}
//                                                                         />
//                                                                     </Grid>
//                                                                 </Grid>
//                                                                 <Typography variant="body2" sx={{ marginTop: "10px" }}>
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         <>
//                                                             <LinearProgress variant="determinate" />
//                                                             <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                 Ready to upload and Extract
//                                                             </Typography>
//                                                         </>)}
//                                                 </Grid>

//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;


// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import {
//     Typography,
//     Box,
//     Button,
//     Grid,
//     Card,
//     CardContent,
//     IconButton,
//     LinearProgress
// } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]);
//     const [responses, setResponses] = useState([]); // Store responses
//     const [loading, setLoading] = useState(false);

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file),
//                 name: file.name,
//                 file: file, // Keep file for upload
//                 status: 'pending' // Initial status for each image
//             }));
//             setUploadedImages([...uploadedImages, ...newImages]);
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index);
//         setUploadedImages(newImages);
//         const newResponses = responses.filter((_, i) => i !== index);
//         setResponses(newResponses);
//     };

//     const handleExtract = async () => {
//         setLoading(true);
//         for (let i = 0; i < uploadedImages.length; i++) {
//             const image = uploadedImages[i];
//             const formData = new FormData();
//             formData.append('file', image.file);

//             try {
//                 // Simulating API request
//                 const response = await simulateAPIRequest(image.file); // Replace with actual API call
//                 const newResponse = [...responses];
//                 newResponse[i] = response.data;
//                 setResponses(newResponse);

//                 const newImages = [...uploadedImages];
//                 newImages[i].status = 'completed'; // Update status to completed
//                 setUploadedImages(newImages);
//             } catch (error) {
//                 console.error("Error uploading image:", error);
//             }
//         }
//         setLoading(false);
//     };

//     // Simulate an API response
//     const simulateAPIRequest = (file) => {
//         return new Promise((resolve) => {
//             setTimeout(() => {
//                 resolve({
//                     status: "success",
//                     data: [
//                         {
//                             box0: "34",
//                             box1: "472039",
//                             box2: "(42-08)",
//                             box3: "09",
//                             box4: "006",
//                             box5: "000",
//                             NumPieces: "60 Pcs",
//                             ItemDesc: "DRY-EX sleeveless T-shirts"
//                         }
//                     ]
//                 });
//             }, 2000); // Simulated delay
//         });
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px",
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%",
//                             maxWidth: "1000px",
//                             minHeight: uploadedImages.length ? "100px" : "250px",
//                             transition: "all 0.3s ease-in-out",
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px",
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name}
//                                                     </Typography>
//                                                 </Grid>

//                                                 <Grid item xs={8}>
//                                                     {responses[index] ? (
//                                                         // Display response data (like shipment label)
//                                                         responses[index].map((label, i) => (
//                                                             <Box key={i} sx={{ marginBottom: '10px' }}>
//                                                                 <Typography variant="h6">
//                                                                     {label.box0} - {label.box1} - {label.box2} - {label.box3} - {label.box4} - {label.box5}
//                                                                 </Typography>
//                                                                 <Typography variant="body2">
//                                                                     {label.NumPieces} - {label.ItemDesc}
//                                                                 </Typography>
//                                                             </Box>
//                                                         ))
//                                                     ) : (
//                                                         image.status === 'completed' ? (
//                                                             <Typography>Completed</Typography>
//                                                         ) : (
//                                                             <>
//                                                                 <LinearProgress variant="determinate" />
//                                                                 <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                                     Ready to upload and Extract
//                                                                 </Typography>
//                                                             </>
//                                                         )
//                                                     )}
//                                                 </Grid>

//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleExtract}
//                                 disabled={loading}
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto',
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;



// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { Typography, Box, Button, Grid, Card, CardContent, LinearProgress, IconButton } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import Header from "../components/Header";
// import "../styles/ExtractorScreenStyles.css"; // Import custom styles

// const ExtractorScreen = () => {
//     const [uploadedImages, setUploadedImages] = useState([]); // State for storing uploaded images

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: "image/jpeg, image/png, image/jpg, image/gif, image/jfif",
//         onDrop: (acceptedFiles) => {
//             // Add new uploaded image to the list
//             const newImages = acceptedFiles.map((file) => ({
//                 preview: URL.createObjectURL(file), // Create a preview URL for the image
//                 name: file.name, // Correctly assign the image name
//             }));
//             setUploadedImages([...uploadedImages, ...newImages]); // Add new images to existing state
//         },
//     });

//     const handleDeleteImage = (index) => {
//         const newImages = uploadedImages.filter((_, i) => i !== index); // Remove the image at the given index
//         setUploadedImages(newImages);
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
//                 {/* Image Uploader */}
//                 <div className="uploader-container">
//                     <Box
//                         {...getRootProps()}
//                         className="dropzone"
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             padding: uploadedImages.length ? "10px" : "40px", // Shrink height when images are uploaded
//                             border: "3px dashed #ccc",
//                             borderRadius: "25px",
//                             cursor: "pointer",
//                             backgroundColor: "#fcfcfc",
//                             width: "100%", // Always full width
//                             maxWidth: "1000px", // Consistent with preview section
//                             minHeight: uploadedImages.length ? "100px" : "250px", // Shrink height when image is added
//                             transition: "all 0.3s ease-in-out", // Smooth shrinking
//                         }}
//                     >
//                         <input {...getInputProps()} />
//                         <Typography variant="h6" align="center" color="textPrimary">
//                             Drag and drop images here
//                         </Typography>
//                         <Typography variant="body2" align="center" color="textSecondary">
//                             Files supported: JPG | PNG | JPEG | GIF | JFIF
//                         </Typography>
//                     </Box>
//                 </div>

//                 {/* Image Preview Box */}
//                 {uploadedImages.length > 0 && (
//                     <div className="preview-container">
//                         <Grid container spacing={2} className="image-preview-grid">
//                             {uploadedImages.map((image, index) => (
//                                 <Grid item xs={12} key={index} className="image-preview-item">
//                                     <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "16px", borderRadius: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
//                                         <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
//                                             <Grid container spacing={2} alignItems="center">
//                                                 {/* Image Preview */}
//                                                 <Grid item xs={2} sx={{ textAlign: "left" }}>
//                                                     <Box
//                                                         component="img"
//                                                         sx={{
//                                                             width: "80px", // Moderate image size
//                                                             height: "auto",
//                                                             borderRadius: "8px",
//                                                             objectFit: "cover",
//                                                             display: "block",
//                                                         }}
//                                                         src={image.preview}
//                                                         alt={`Uploaded image ${index + 1}`}
//                                                     />
//                                                     <Typography variant="caption" display="block">
//                                                         {image.name} {/* Show the image name */}
//                                                     </Typography>
//                                                 </Grid>

//                                                 {/* Loading Bar and Text */}
//                                                 <Grid item xs={8}>
//                                                     <LinearProgress variant="determinate" value={100} />
//                                                     <Typography variant="body2" color="textSecondary" sx={{ marginTop: "8px" }}>
//                                                         Ready to upload and Extract
//                                                     </Typography>
//                                                 </Grid>

//                                                 {/* Delete Button */}
//                                                 <Grid item xs={2} sx={{ textAlign: "right" }}>
//                                                     <IconButton aria-label="delete" onClick={() => handleDeleteImage(index)}>
//                                                         <DeleteIcon />
//                                                     </IconButton>
//                                                 </Grid>
//                                             </Grid>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>

//                         {/* Submit Button */}
//                         <div className="submit-container">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 sx={{
//                                     marginTop: "20px",
//                                     backgroundColor: "#3f51b5",
//                                     float: 'right',
//                                     width: 'auto', // Smaller button
//                                     padding: '10px 20px',
//                                 }}
//                             >
//                                 Submit and Extract
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ExtractorScreen;

